<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveDealer)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ modalTitle(name) }} <strong>Dealer</strong></span>
					<span v-if="dealer.id">#{{ dealer.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<InputWithValidation class="mb-5" rules="required|min:3" type="text" label="Nombre" size="is-medium" v-model="dealer.name" />
					<InputWithValidation class="mb-5" rules="required|min:2" type="text" label="Retailer ID" size="is-medium" v-model="dealer.retailer_id" />
					<div class="columns">
						<div class="column">
							<InputWithValidation rules="required" type="text" label="Teléfono" size="is-medium" v-model="dealer.phone" />
						</div>
						<div class="column">
							<InputWithValidation rules="required" type="text" label="RFC" size="is-medium" v-model="dealer.document" />
						</div>
					</div>
					<h3 class="is-size-5 is-medium has-text-primary">Ubicación</h3>
					<hr class="my-2 divider">
					<div class="columns">
						<div class="column">
							<InputWithValidation rules="required" type="text" label="Latitud" size="is-medium" v-model="dealer.lat" />
						</div>
						<div class="column">
							<InputWithValidation rules="required" type="text" label="Longitud" size="is-medium" v-model="dealer.lon" />
						</div>
					</div>
					<InputWithValidation class="mb-5" rules="required" type="text" label="Dirección" size="is-medium" v-model="dealer.address" />
					<div class="columns mb-5">
						<div class="column">
							<InputWithValidation rules="required" type="text" label="Ciudad" size="is-medium" v-model="dealer.city" />
						</div>
						<div class="column">
							<InputWithValidation rules="required" type="text" label="Estado" size="is-medium" v-model="dealer.state" />
						</div>
					</div>

					<div v-if="dealers.length > 0">
						<b-field label="Dealers Agrupados" class="mb-4">
							<multiselect v-model="dealer.group" name="delaers" :options="dealers" label="name" track-by="name" placeholder="" multiple preserve-search :close-on-select="false" :clear-on-select="false" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Eliminar">
								<span slot="noResult">No se encontraron dealers</span>
							</multiselect>
						</b-field>
					</div>

					<small class="modal-updated" v-if="dealer.updated_at">Última Actualización: {{ format(dealer.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Cerrar</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Guardar</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/mixins/generic'

export default {
	components: {
		InputWithValidation,
		ValidationObserver,
		Multiselect
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			dealer: {
				type: 1,
				name: '',
				retailer_id: '',
				phone: '',
				document: '',
				address: '',
				lat: '',
				lon: '',
				city: '',
				state: '',
				region: ''
			},
			dealers: []
		}
	},
	methods: {
		async saveDealer() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post(`dealer/store`, this.dealer)
				const { status } = response
				if (status === 201) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/dealers')
					successToast('¡El dealer ha sido <strong>registrado</strong> exitosamente!')
					eventHub.$emit('reload-dealers')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('¡Ocurrió un <strong>error</strong> al crear el dealer!')
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`dealer/update/${this.id}`, this.dealer)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/dealers')
					successToast('¡El dealer ha sido <strong>actualizado</strong> exitosamente!')
					eventHub.$emit('reload-dealers')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('¡Ocurrió un <strong>error</strong> al actualizar el dealer!')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`dealer/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.dealer = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async getAllDealers() {
			try {
				const response = await Api.get('dealer/findAll')

				if (response.status === 200) {
					this.dealers = response.data.filter((d) => {
						if (d.group_id && d.group_id != this.dealer.group_id) {
							d.$isDisabled = true
						}

						return d.id != this.dealer.id
					})
				}
			} catch (e) {
				console.log(e)
			}
		}
	},
	mounted() {
		this.findById()
		this.getAllDealers()
	}
}
</script>
